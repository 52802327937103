const getTenantsCardsData = (
	paymentHistory,
	tenant,
	totalGamesPlayed,
	isLoading = false
) => {
	let tenantData = paymentHistory?.find((h) => h?.slug == tenant);
	let result = [
		{
			id: 1,
			title: "Total Games Played",
			count: isLoading ? "fetching..." : totalGamesPlayed,
			bgColor: "#f2af2d",
		},

		{
			id: 2,
			title: "Defaulters",
			count: tenantData?.defaulter,
			bgColor: "#f1484f",
		},
		{
			id: 3,
			title: "Loan disbursed",
			count: tenantData?.loanDisbursed,
			bgColor: "#0a979b",
		},
		{
			id: 4,
			title: "Repayment records",
			count: tenantData?.repaymentRecords,
			bgColor: "#0000ff",
		},
		{
			id: 5,
			title: "Good Borrowers",
			count: Number(tenantData?.loanDisbursed) - Number(tenantData?.defaulter),
			bgColor: "#8fcf7c",
		},
	];

	return result;
};

export default getTenantsCardsData;
